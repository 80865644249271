<template>
  <div class="newsList">
    <div class="conent">
      <div class="lbt">
        <template
          v-if="
            !listShowZT &&
            (currentTabData.cid === $columnIdFile.ewztjy1 ||
              currentTabData.cid === $columnIdFile.ewsrxx1)
          "
          ><img
            src="../assets/img/2304231541350878911.jpg"
            v-if="currentTabData.cid === $columnIdFile.ewztjy1" />
          <img
            src="../assets/img/bann2.jpg"
            v-else-if="currentTabData.cid === $columnIdFile.ewsrxx1"
        /></template>
        <template v-else>
          <img v-if="HerShow" src="../assets/img/banner.jpg" />
          <img
            v-else-if="!HerShow && Hertype == 0"
            src="../assets/img/2304231541350878911.jpg"
          />
          <img
            v-else-if="!HerShow && Hertype == 1"
            src="../assets/img/bann2.jpg"
          />
          <img
            v-else-if="!HerShow && Hertype == 2"
            src="../assets/img/bann3.jpg"
          />
          <img
            v-else-if="!HerShow && Hertype == 3"
            src="../assets/img/banner.jpg"
          />
        </template>
      </div>
      <div class="w1380 lmy">
        <div class="lmy_box">
          <div class="lmy-right">
            <div class="lmy-right-list" v-if="$route.query?.status !== 'ztsc'">
              <div class="zlm">
                <p class="news 3">{{ $t("newsInformation") }}</p>
                <p>
                  <span><img src="../assets/img/newsIcon.png" /></span
                  >{{
                    showTab ? $t("newsInformation_1") : $t("recruitStudent_7")
                  }}
                </p>
              </div>
              <div class="lmlb" v-if="showTab">
                <ul>
                  <li
                    v-for="(item, index) in lmListFilter"
                    :key="index"
                    :class="currentIm == item.name ? 'current' : ''"
                    id="item"
                    @click="wrapLm(item)"
                    :style="item.name != '专题' ? '' : 'width: 12.5vw;'"
                  >
                    <span v-if="item.name != '专题'">{{ item.name }}</span>
                    <span v-else> {{ $t("newsInformation_2") }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="lmy-center">
            <div
              style="display: flex; justify-content: space-between"
              v-if="!HerShow"
            >
              <div class="columnName" v-if="columnName && currentIm === '专题'">
                {{ columnName }}
              </div>
              <div
                class="columnName"
                v-if="$route.query?.status === 'ztsc'"
                style="font-size: 1.04167vw; cursor: pointer"
                @click="ztSeeMore"
              >
                更多专题>
              </div>
            </div>
            <div class="lmy-left 222" v-if="flag && !type">
              <div v-if="false">
                <template
                  v-if="
                    tableList.length && tableList[0].mapProperties.erOriginLink
                  "
                >
                  <a
                    class="lmy-left-top"
                    v-if="tableList.length > 0 && pageNum == 0"
                    :href="tableList[0].mapProperties.erOriginLink"
                    target="_blank"
                    style="display: block"
                  >
<!--                    <div v-if="tableList[0].columnIsTop" class="column_is_top">-->
<!--                      {{ $t("top") }}-->
<!--                    </div>-->
                    <img :src="tableList[0].thumbnailInfo.thumbnailUrl" />
                    <span>{{ $t("newsInformation_3") }}</span>
                    <div class="ttnr">
                      <div class="ttnr-left">
                        <p>
                          {{
                            $moment(tableList[0].publishTime)
                              .format("YYYY.MM.DD")
                              .split(".")[2]
                          }}
                        </p>
                        <p>
                          {{
                            $moment(tableList[0].publishTime)
                              .format("YYYY.MM.DD")
                              .slice(0, 7)
                          }}
                        </p>
                      </div>
                      <div class="ttnr-right" @click="toDetail(tableList[0])">
                        <div
                          class="ttnr-right-bt ellipsis2"
                          style="height: 3.33333vw"
                        >
                          <a :title="tableList[0].title"
                            >{{ tableList[0].title }}
                          </a>
                        </div>
                        <div
                          class="ttnr-right-zy ellipsis2"
                          style="height: 1.77083vw"
                        >
                          {{ tableList[0].description }}
                        </div>
                      </div>
                    </div>
                  </a>
                </template>
                <template v-else>
                  <div
                    class="lmy-left-top"
                    v-if="tableList.length > 0 && pageNum == 0"
                    @click="toDetail(tableList[0])"
                  >
<!--                    <div v-if="tableList[0].columnIsTop" class="column_is_top">-->
<!--                      {{ $t("top") }}-->
<!--                    </div>-->
                    <img :src="tableList[0].thumbnailInfo.thumbnailUrl" />
                    <span>{{ $t("newsInformation_3") }}</span>
                    <div class="ttnr">
                      <div class="ttnr-left">
                        <p>
                          {{
                            $moment(tableList[0].publishTime)
                              .format("YYYY.MM.DD")
                              .split(".")[2]
                          }}
                        </p>
                        <p>
                          {{
                            $moment(tableList[0].publishTime)
                              .format("YYYY.MM.DD")
                              .slice(0, 7)
                          }}
                        </p>
                      </div>
                      <div class="ttnr-right" @click="toDetail(tableList[0])">
                        <div
                          class="ttnr-right-bt ellipsis2"
                          style="height: 3.33333vw"
                        >
                          <a :title="tableList[0].title"
                            >{{ tableList[0].title }}
                          </a>
                        </div>
                        <div
                          class="ttnr-right-zy ellipsis2"
                          style="height: 1.77083vw"
                        >
                          {{ tableList[0].description }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="lmy-left-bottom">
                  <div class="news-list 10">
                    <div
                      class="news-list-item"
                      v-for="(item, index) in pageNum == 0
                        ? tableList.slice(1, 9)
                        : tableList"
                      :key="index"
                    >
                      <template v-if="item.mapProperties.erOriginLink">
                        <a
                          :href="item.mapProperties.erOriginLink"
                          target="_blank"
                          style="display: block; display: flex"
                        >
<!--                          <div v-if="item.columnIsTop" class="list_is_top">-->
<!--                            {{ $t("top") }}-->
<!--                          </div>-->
                          <div class="list-date">
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .split(".")[2]
                              }}
                            </p>
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .slice(0, 7)
                              }}
                            </p>
                          </div>
                          <div class="list-title">
                            <div class="li_bt ellipsis" :title="item.title">
                              {{ item.title }}
                            </div>
<!--                            <p class="li_zy ellipsis">-->
<!--                              {{ item.description }}-->
<!--                            </p>-->
                          </div>
                        </a>
                      </template>
                      <template v-else>
                        <div @click="toDetail(item)" style="display: flex">
<!--                          <div v-if="item.columnIsTop" class="list_is_top">-->
<!--                            {{ $t("top") }}-->
<!--                          </div>-->
                          <div class="list-date">
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .split(".")[2]
                              }}
                            </p>
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .slice(0, 7)
                              }}
                            </p>
                          </div>
                          <div class="list-title">
                            <div class="li_bt ellipsis" :title="item.title">
                              {{ item.title }}
                            </div>
<!--                            <p class="li_zy ellipsis">-->
<!--                              {{ item.description }}-->
<!--                            </p>-->
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!listShowZT">
                <div class="lmy-left-bottom">
                  <div class="news-list 11">
                    <div
                      class="news-list-item"
                      v-for="(item, index) in tableList"
                      :key="index"
                    >
                      <template v-if="item.mapProperties.erOriginLink">
                        <a
                          :href="item.mapProperties.erOriginLink"
                          target="_blank"
                          style="display: flex"
                        >
<!--                          <div v-if="item.columnIsTop" class="list_is_top">-->
<!--                            {{ $t("top") }}-->
<!--                          </div>-->
                          <div class="list-date">
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .split(".")[2]
                              }}
                            </p>
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .slice(0, 7)
                              }}
                            </p>
                          </div>
                          <div class="list-title">
                            <div class="li_bt ellipsis" :title="item.title">
                              {{ item.title }}
                            </div>
<!--                            <p class="li_zy ellipsis">-->
<!--                              {{ item.description }}-->
<!--                            </p>-->
                          </div>
                        </a>
                      </template>
                      <template v-else>
                        <div @click="toDetail(item)" style="display: flex">
<!--                          <div v-if="item.columnIsTop" class="list_is_top">-->
<!--                            {{ $t("top") }}-->
<!--                          </div>-->
                          <div class="list-date">
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .split(".")[2]
                              }}
                            </p>
                            <p>
                              {{
                                $moment(item.publishTime)
                                  .format("YYYY.MM.DD")
                                  .slice(0, 7)
                              }}
                            </p>
                          </div>
                          <div class="list-title">
                            <div class="li_bt ellipsis" :title="item.title">
                              {{ item.title }}
                            </div>
<!--                            <p class="li_zy ellipsis">-->
<!--                              {{ item.description }}-->
<!--                            </p>-->
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 专题栏目 -->
              <div v-else-if="listShowZT">
                <div class="lmy-left-bottom" v-if="tableList.length > 0">
                  <div class="news-list news-list-zt_data 12">
                    <div
                      class="news-list-zt"
                      v-for="(item, index) in tableList"
                      :key="index"
                      @click="toZTListData(item)"
                    >
                      <div class="news-list-date">
                        <p style="margin-top: 0.78125vw">
                          <span style="font-size: 1.82292vw">{{
                            $moment.utc(item.updated).local().format("MM")
                          }}</span>月
                        </p>
                        <p>
                          {{
                            $moment
                              .utc(item.updated)
                              .local()
                              .format("YYYY年")
                          }}
                        </p>
                      </div>
                      <div class="list-title">
                        <div class="li_bt ellipsis" :title="item.name">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 专题列表 -->
              <div v-else>
                <div class="lmy-left-bottom" v-if="tableList.length > 0">
                  <div class="news-list 12">
                    <div
                      class="news-list-item"
                      v-for="(item, index) in tableList"
                      :key="index"
                      @click="toZTList(item)"
                    >
<!--                      <div v-if="item.columnIsTop" class="list_is_top">-->
<!--                        {{ $t("top") }}-->
<!--                      </div>-->
                      <div class="list-date">
                        <p>
                          {{
                            $moment(item.createTime)
                              .format("YYYY.MM.DD")
                              .split(".")[2]
                          }}
                        </p>
                        <p>
                          {{
                            $moment(item.createTime)
                              .format("YYYY.MM.DD")
                              .slice(0, 7)
                          }}
                        </p>
                      </div>
                      <div class="list-title">
                        <div class="li_bt ellipsis" :title="item.title">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else style="text-align: center; font-size: 1.04167vw">
                  {{ $t("noData") }}
                </div>
              </div>
            </div>
            <div class="text-detail" v-if="!flag && type == '文本'">
              <TextDetail :content="currentContent"></TextDetail>
            </div>
            <div class="video-detail" v-if="!flag && type == '视频'">
              <VideoDetail></VideoDetail>
            </div>
            <div class="pic-detail" v-if="!flag && type == '图片'">
              <PicDetail></PicDetail>
            </div>
          </div>
          <div class="lmy-page" v-if="flag && !type && !listShowZT">
            <Pagination
              @pageChange="pageChange"
              v-if="tableList.length > 0"
              :totalNum="total"
              :currentPage="pageNum"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 相关阅读 -->
    <div class="xgyd w1380" v-if="!flag && (type == '文本' || type == '图片')">
      <p class="fbt">{{ $t("relatedReading") }}</p>
      <p class="bt">
        <i></i>
        <span class="name">{{ $t("relatedReading_1") }}</span>
        <span class="gd"
          ><a @click="more">{{ $t("viewMore") }}&gt;</a></span
        >
      </p>
      <ul class="list">
        <li
          class="list-item"
          v-for="(item, index) in tableList.slice(0, 6)"
          :key="index"
        >
          <div class="date">
            <p>
              {{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[1]
              }}{{ $t("month_1")
              }}{{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[2]
              }}{{ $t("day_1") }}
            </p>
            <p>
              {{ $moment(item.publishTime).format("YYYY.MM.DD").split(".")[0]
              }}{{ $t("year_1") }}
            </p>
          </div>
          <div class="list-list" @click="toDetail(item)">
            <a>{{ item.title }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TextDetail from "./textDetail";
import VideoDetail from "./videoDetail";
import PicDetail from "./picDetail";
import Pagination from "../components/Pagination";
export default {
  components: {
    TextDetail,
    VideoDetail,
    PicDetail,
    Pagination,
  },
  data() {
    return {
      listShowZT: false,
      tablist: [],
      currentContent: null, //选中的数据的内容
      tableList: [],
      flag: true,
      type: null,
      pageNum: 0,
      pageSize: 10,
      total: 0,
      currentTabData: "", //选中tab的内容
      currentIm: "要闻速递",
      lmList: [
        { name: "要闻速递", type: "ywsd", cid: this.$columnIdFile.ywsd },
        { name: "通知公告", type: "tzgg", cid: this.$columnIdFile.tzgg },
        { name: "学术动态", type: "xsdt", cid: this.$columnIdFile.xsdt },
        { name: "媒体二外", type: "mtew", cid: this.$columnIdFile.mtew },
        { name: "校园看点", type: "xykd", cid: this.$columnIdFile.xykd },
        { name: "专题", type: "ztlm", cid: this.$columnIdFile.ztlm },
        { name: "招聘", type: "zp", cid: this.$columnIdFile.zp },
      ],
      HerShow: true,
      Hertype: -1,
      showTab: true,
      columnName: "",
    };
  },
  created() {
    this.setScroll();
    this.currentIm = localStorage.getItem("currentIm") || "要闻速递";
    this.currentTabData = this.lmList.filter(
      (li) => li.name == this.currentIm
    )[0];
    // if (this.currentIm == "专题") {
    //   // this.getZTList();
    //   // this.getList();
    //   this.getZTLMList()
    // } else {
    //   this.getList();
    // }
    let that = this;
    if (that.currentIm == "专题") {
      const state = this.$route.query?.status;
      if (state === "ztsc") {
        that.HerShow = false;
        this.Hertype = this.$route.query?.type;
        that.currentTabData.cid = that.$route.query.cid;
        that.getList();
      } else {
        that.HerShow = true;
        that.getZTLMList();
      }
    } else {
      that.HerShow = true;
      if (this.currentTabData.type == "zp") {
        this.showTab = false;
      }
      this.getList();
    }
  },
  methods: {
    toZTListData(item) {
      this.HerShow = false;
      this.currentIm = "专题";
      this.listShowZT = false;
      this.currentTabData.cid = item.uuid;
      this.getList("init");
    },
    wrapLm(item) {
      this.columnName = "";
      this.flag = true;
      this.currentIm = item.name;
      this.currentTabData = item;
      localStorage.setItem("currentIm", item.name);
      this.type = null;
      this.HerShow = true;
      if (this.currentIm == "专题") {
        this.listShowZT = true;
        this.getZTLMList();
        // this.getZTList();
        // this.getList("init");
      } else {
        this.listShowZT = false;
        this.getList("init");
      }
    },
    async getZTLMList() {
      this.currentIm = "专题";
      this.listShowZT = true;
      this.tableList = [];
      const res = await this.API.basic.getColumn();
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex((i) => {
          return i.uuid == this.$columnIdFile.ztlm;
        });
        findColumn != "-1"
          ? (this.tableList = res.data[findColumn].children)
          : (this.tableList = []);
      }
    },
    async getZTList(init) {
      if (init) {
        this.pageNum = 0;
      }
      this.tableList = [];
      const res = await this.API.basic.getZTList();
      // const res = await this.API.basic.getSignaturesList();
      if (res) {
        this.tableList = res.data;
      }
    },
    async getList(init) {
      this.setScroll();
      if (init) {
        this.pageNum = 0;
      }
      this.tableList = [];
      const data = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize,
        columnIds: this.currentTabData.cid,
      };
      const res = await this.API.basic.getlist(data);
      if (res) {
        this.tableList = res.data.content ? res.data.content : [];
        this.total = res.data.totalElements;
        if (this.tableList.length > 0 && this.$route.query?.status == "ztsc") {
          this.columnName = this.tableList[0]?.columnName;
        } else {
          this.columnName = "";
        }
      }
    },
    toZTList(item) {
      // localStorage.setItem("currentIm", "专题");
      this.$router.push({
        path: "/ztlist",
        query: {
          title: item.fullName,
          channelId: item.channelId,
          columnId: item.uuid,
        },
      });
    },
    toDetail(item) {
      this.setScroll();
      this.currentContent = item;
      this.flag = false;
      this.type = "文本";
      const params = {
        cid: item.columnId,
        tab: item.columnName,
        id: item.id,
      };
      if (this.$route.query?.status === "ztsc") {
        params.status = "ztsc";
      }
      if (this.$route.query?.type !== "") {
        params.type = this.$route.query?.type;
      }
      this.$router.push({
        path: "/newsDetail",
        query: params,
      });
    },
    // 滚动条初始化高度设置为0
    setScroll() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 180;
      });
    },
    more() {
      this.flag = true;
      this.type = null;
      this.currentIm = this.currentTabData.name;
      this.setScroll();
    },
    pageChange(val) {
      this.pageNum = val - 1;
      this.getList();
    },
    ztSeeMore() {
      this.HerShow = true;
      this.getZTLMList();
    },
  },
  computed: {
    lmListFilter() {
      return this.lmList.filter((item) => {
        return item.type !== "zp";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news-list-zt_data {
  display: flex;
  flex-wrap: wrap;
}
.news-list-zt {
  margin: 0 25px 20px 0;
  padding-block: 20px;
  font-size: 18px;
  width: 480px;
  // max-width: 200px;
  border-bottom: 1px solid #ccc;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;

  .list-title {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
}
.news-list-date {
  width: 110px;
  height: 100px;
  color: #a12916;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.newsList {
  overflow-y: auto;
  background: #f5f5f5;
  padding-bottom: 50px;
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .conent .lbt {
    // z-index: -1;
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
  .lmy {
    position: relative;
    background: #fff;
    margin-top: -128px;
    z-index: 1;
  }
  .lmy-right {
    border-bottom: 1px solid #eee;
  }
  .lmy-right-list {
    display: flex;
  }
  .lmy-right-list .zlm {
    width: 245px;
    padding: 20px 40px 0 40px;
    .news {
      font-size: 56px;
      text-align: center;
      color: #a12916;
    }
  }
  .lmy-right-list p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    color: #a12916;
  }
  .lmy-right-list p span img {
    margin-right: 5px;
  }
  .lmy-right-list ul {
    display: flex;
    width: 100%;
    height: 128px;
  }
  .lmy-right-list ul li {
    height: 128px;
    line-height: 128px;
    text-align: center;
    font-size: 20px;
    width: 166px;
    span {
      color: #333;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li.current {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  .lmy-right-list ul li:hover {
    background: #a12916;
    span {
      color: #fff;
      cursor: pointer;
    }
  }
  a {
    color: #333;
  }
  .lmy-center {
    margin-top: 25px;
    // margin-bottom: 50px;
    .columnName {
      font-size: 30px;
      text-align: left;
      color: #a12916;
      padding: 0.104167rem 0.208333rem;
      margin-bottom: 0.104167rem;
      // border-bottom: 0.005208rem solid #eee;
    }
  }
  .lmy-center .lmy-left {
    width: 1030px;
    margin: 0 auto;
  }
  .lmy-center .lmy-left .lmy-left-top {
    position: relative;
    height: 479px;
  }
  .navRoute {
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333;
    margin-bottom: 40px;
  }
  .news-list-item {
    position: relative;
  }
  .list_is_top {
    position: absolute;
    right: 100px !important;
    top: 0px !important;
    width: 50px;
    color: #fff;
    height: 50px;
    font-size: 16px;
    background: url("../assets/img/fire.png") no-repeat;
    background-size: 100% 100%;
    line-height: 68px;
    font-weight: bolder;
    text-align: center;
  }
  .column_is_top {
    position: absolute;
    right: 8px !important;
    top: 10px !important;
    width: 60px;
    color: #fff;
    height: 60px;
    font-size: 20px;
    background: url("../assets/img/fire.png") no-repeat;
    background-size: 100% 100%;
    line-height: 80px;
    font-weight: bolder;
    text-align: center;
  }
  .lmy-center .lmy-left .lmy-left-top img {
    width: 100%;
    height: 100%;
  }
  .lmy-center .lmy-left .lmy-left-top span {
    display: block;
    position: absolute;
    background: url("../assets/img/topNews.png") no-repeat;
    width: 97px;
    height: 41px;
    font-size: 14px;
    color: #fff;
    top: 30px;
    left: -10px;
    line-height: 50px;
    text-align: center;
  }
  .ttnr {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    background: url("../assets/img/maskBg1.png");
    background-repeat: repeat-x;
    color: #fff;
    width: 1030px;
    height: 165px;
    font-size: 12px;
    padding: 50px 135px 0 40px;
  }
  .ttnr-left {
    width: 60px;
    text-align: center;
    margin-top: 20px;
    margin-right: 28px;
  }
  .ttnr-left p:first-child {
    font-size: 22px;
  }
  .ttnr-right {
    width: 760px;
    .ttnr-right-bt {
      margin-bottom: 10px;
    }
  }
  .ttnr-right a {
    font-size: 22px;
    color: #fff;
  }
  .ttnr-right a:hover {
    color: #a12916;
  }
  .lmy-left-bottom {
    margin-top: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item {
    height: 100px;
    border-top: 1px solid #ddd;
    background: #fff url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .lmy-left-bottom .news-list .news-list-item:first-of-type {
    border-top: none;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date {
    width: 50px;
    text-align: center;
    color: #a12916;
    margin-right: 50px;
    font-size: 12px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-date p:first-child {
    font-size: 34px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title {
    width: 730px;
    //line-height: 180%;
    display: flex;
    align-items: center;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_bt {
    height: 25px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    //margin-bottom: 10px;
  }
  .lmy-left-bottom .news-list .news-list-item .list-title .li_zy {
    height: 25px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .lmy-left-bottom .news-list .news-list-item:hover {
    background: #a12916 url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    color: #fff;
    .list-date {
      color: #fff;
    }
    .list-title .li_bt {
      color: #fff;
    }
    .list-title .li_zy {
      color: #fff;
    }
  }
  .lmy-page {
    text-align: center;
    margin-top: 38px;
    padding-bottom: 65px;
  }
  ::v-deep .el-pager li.active {
    background-color: #a12916 !important;
    colre: #fff;
  }
  ::v-deep .el-pager li:hover {
    color: #a12916 !important;
  }
  ::v-deep .el-pagination button:hover {
    color: #a12916 !important;
  }
  .xgyd {
    margin-top: 50px;
  }
  .xgyd .fbt {
    color: #ababab;
    font-size: 24px;
    padding-left: 5px;
  }
  .xgyd .bt {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .xgyd .bt i {
    float: left;
    width: 4px;
    height: 30px;
    background: #ad2e1b;
    border-radius: 2px;
  }
  .xgyd .bt .name {
    font-size: 28px;
    color: #000;
    float: left;
    margin-left: 5px;
  }
  .xgyd .bt .gd {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .bt .gd a {
    float: right;
    font-size: 16px;
    color: #a12916;
  }
  .xgyd .list {
    display: flex;
    flex-wrap: wrap;
  }
  .xgyd .list li {
    width: 672.5px;
    height: 108px;
    background: #fff url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
  }
  .xgyd .list li:nth-of-type(2n + 1) {
    margin-right: 35px;
  }
  .xgyd .list li .date {
    float: left;
    margin-top: 17px;
  }
  .xgyd .list li .date p {
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    text-align: center;
  }
  .xgyd .list li .date p:first-child {
    border-bottom: 2px solid #f9f9f9;
  }
  .xgyd .list li .list-list {
    float: left;
    height: 80px;
    margin-left: 20px;
    margin-top: 7px;
    border-left: 2px solid #f9f9f9;
    width: 440px;
    padding-top: 10px;
    line-height: 30px;
    padding-left: 10px;
    font-size: 18px;
  }
  .xgyd .list li:hover {
    width: 672.5px;
    height: 108px;
    background: #a12916 url("../assets/img/jt.png") no-repeat;
    background-position: 95% center;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
</style>
